const videoHero = document.querySelector('.video-hero');

window.addEventListener('load', () => {
	if (document.documentElement.classList.contains('ccm-blocked')) {
		if (localStorage.getItem('ccm_consent') === null) {
			videoHero.classList.add('user-no-consent');
			document
				.querySelector('.ccm--save-settings')
				.addEventListener('click', () => {
					videoHero.classList.remove('user-no-consent');
					videoHero.classList.add('video-hero-playing');
				});
		}
	}
	// else {
	// 	document.querySelector('.video-hero-overlap').classList.add('hidden');
	// }
});

const listener = window.addEventListener('blur', function() {
	window.setTimeout(function() {
		if (document.activeElement instanceof HTMLIFrameElement) {
			videoHero.classList.add('video-hero-playing');
		}
	}, 5);
	window.removeEventListener('blur', listener);
});
